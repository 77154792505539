import React from "react"

import DefaultLayout from "../layouts/default"
import TwoThirdsGrid from "../components/layout/TwoThirdsGrid"
import Text from "../components/text/Text"
import HeadlineWithSub from "../components/text/HeadlineWithSub"
import DottedLineHorizontal from "../components/DottedLineHorizontal"
import RoundedImage from "../components/RoundedImage"

const active = {
  title: "Literatur"
}

const Literatur = () => 
<DefaultLayout image="/assets/images/IMG_7002.jpg" active={active}>
  <TwoThirdsGrid textRight>
    <div>
      <HeadlineWithSub>
        <h1>Literatur</h1>
      </HeadlineWithSub>
      <div className="mobileDisplayNone"><RoundedImage image="/assets/images/IMG_4196.jpeg" contentImage/></div>
    </div>
    <Text>
      <p>
        Arvay, C. G. (2015). Der Biophilia-Effekt – Heilung aus dem Wald. Wien: Edition a.
      </p>
      
      <p>
        Barton, J., Pretty, J. (2010). What is the best dose of nature and green exercise for improving mental health? A multi-study analysis. <br />
        <a href="https://www.researchgate.net/publication/42587600_What_is_the_Best_Dose_of_Nature_and_Green_Exercise_for_Improving_Mental_Health_A_Multi-Study_Analysis" target="_blank" rel="noreferrer">
          https://www.researchgate.net/&shy;publication/&shy;42587600_&shy;What_is_the_&shy;Best_Dose_of&shy;_Nature_and_Green&shy;_Exercise_&shy;for_Improving&shy;_Mental_Health_A&shy;_Multi-Study_&shy;Analysis
        </a>
      </p>
      
      <p>
        Berman, M., Jonides, J., Kaplan, S. (2008). The cognitive benefits of interacting with nature.<br />
        <a href="https://www.researchgate.net/publication/23718837_The_Cognitive_Benefits_of_Interacting_With_Nature" target="_blank" rel="noreferrer">https://www.researchgate.net/publication/23718837_The_Cognitive_Benefits_of_Interacting_With_Nature</a>
      </p>
      
      <p>
        Bratman, G., Hamilton, P., Hahn, K., Daily, G., Gross, J., (2015). Nature experience reduces rumination and subgenual prefrontal cortex activation. <br />
        <a href="https://www.pnas.org/content/112/28/8567" target="_blank" rel="noreferrer">https://www.pnas.org/content/112/28/8567</a>
      </p>
      
      <p>Flade, A. (2010). Natur psychologisch betrachtet. Göttingen: Huber</p>
      
      <p>Haubenhofer, D. (2019). Wissen-schaf(f)t-Garten-Therapie. In: Petzold, H., Ellerbrock, B., Hömberg, R. (Hrsg.). Die Neuen Naturtherapien. Handbuch der Garten-, Landschafts-, Wald- und Tiergestützten Therapie. Band I: Grundlagen Garten- und Landschaftstherapie. (S. 159 – 177) Bielefeld: Aisthesis Verlag</p>
      
      <p>Hume, A. (2020). Wirkung der Natur auf den Körper. In Gans, C., Dienemann, K., Hume, A., Lorino, A. Arbeitsraum Natur. Handbuch für Coaches, Therapeuten, Trainer und Organisationen (S. 37 – 41). Wiesbaden: Springer.</p>
      
      <p>Kaplan, R., Kaplan, S. (1989). The experience of nature. A psychological perspective. Cambridge: Cambridge University Press</p>
      
      <p>Knümann, P. (2019). Naturtherapie. Mit Naturerfahrungen Beratung und Psychotherapie bereichern. Weinheim: Beltz</p>
      
      <p>Miyazaki, Y., (2018). Shinrin Yoku – Heilsames Waldbaden: Die japanische Therapie für innere Ruhe, erholsamen Schlaf und ein starkes Immunsystem. München: Irisana</p>
      
      <p>Passmore, H., Holder, M. (2017). Noticing nature: individual and social benefits of a two-week intervention.<br />
      <a href="https://www.researchgate.net/publication/304837616_Noticing_nature_Individual_and_social_benefits_of_a_two-week_intervention" target="_blank" rel="noreferrer">https://www.researchgate.net/publication/304837616_Noticing_nature_Individual_and_social_benefits_of_a_two-week_intervention</a></p>
      
      <p>Schuh, (2004). Klima- und Thalassotherapie. Grundlagen und Praxis. Stuttgart: MVS
      Shanahan, D., Bush, R., Gaston, K., Lin, B., Dean, J., Barber, E., Fuller, R. (2016). Health benefits from nature eperiences depend on dose.<br /><a href="https://www.pnas.org/content/112/28/8567" target="_blank" rel="noreferrer">https://www.pnas.org/content/112/28/8567</a></p>
      
      <p>Ulrich, R., Simons, R., Losito, B., Fiorito, E., Miles, A., Zelson, M. (1991). Stress recovery during exposure to natural and urban environments<a href="https://www.ambiviro.com/beta/wp-content/themes/blankslate/download/pdf/stress-recovery-during-exposure-to-natural-and-urban-environments.pdf" target="_blank" rel="noreferrer">https://www.ambiviro.com/beta/wp-content/themes/blankslate/download/pdf/stress-recovery-during-exposure-to-natural-and-urban-environments.pdf</a></p>

      <p>Wilson, E.O. (1984). Biophilia. Cambridge: Harvard University Press</p>
      
      <DottedLineHorizontal />

      <p>
        <strong>Strichpunkt</strong>
      </p>

      <p><a href="https://de.wikipedia.org/wiki/Semikolon" target="_blank" rel="noreferrer">https://de.wikipedia.org/wiki/Semikolon</a></p>
      
      <p>
        <a href="https://www.sueddeutsche.de/kultur/semikolon-zeichen-unserer-zeit-1.2124673" target="_blank" rel="noreferrer">https://www.sueddeutsche.de/kultur/semikolon-zeichen-unserer-zeit-1.2124673</a>
      </p>

      <p>
        <a href="https://d-nb.info/1008430099/34" target="_blank" rel="noreferrer">https://d-nb.info/1008430099/34</a>
      </p>

      <DottedLineHorizontal />

      <p>
        <strong>Links</strong>
      </p>

      <p><a href="offenergarten.ch" target="_blank" rel="noreferrer">offenergarten.ch</a></p>

      <p><a href="bioterra.ch" target="_blank" rel="noreferrer">bioterra.ch</a></p>

      <p><a href="sggk.ch" target="_blank" rel="noreferrer">sggk.ch</a></p>

      <p><a href="https://www.familienmediation.ch/de/familienmediation/was-ist-mediation-wann-ist-mediation-geeignet" target="_blank" rel="noreferrer">https://www.familienmediation.ch/de/familienmediation/was-ist-mediation-wann-ist-mediation-geeignet</a></p>

      <p><a href="iac.ch" target="_blank" rel="noreferrer">iac.ch</a></p>

    </Text>
  </TwoThirdsGrid>
</DefaultLayout>

export default Literatur