import React from "react"
import styled from "styled-components"

import {breakpoints, spacers} from "../styles/variables"

const DottedLine = styled.div`
    width: 100%;
    height: 3px;
    background: url('/assets/gestaltungselemente/punkt-horizontal.svg');
    background-repeat: repeat-x;
    margin-bottom: ${spacers.s}px;

    @media screen AND (min-width: ${breakpoints.m}px) {
        margin-bottom: 26px;
    }
`

const DottedLineHorizontal = () => <DottedLine></DottedLine>

export default DottedLineHorizontal